:root {
  --root-background-color: #f9fafc;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--root-background-color);
}

* {
  box-sizing: border-box;
  font-family: Inter, Helvetica, 'sans-serif';
}
.rdw-editor-main span {
  font-family: Arial, sans-serif;
}

.rdw-center-aligned-block * {
  text-align: center !important;
}

.rdw-left-aligned-block * {
  text-align: left !important;
}

.rdw-right-aligned-block * {
  text-align: right !important;
}

.rdw-justify-aligned-block * {
  text-align: justify !important;
}
